<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="branches"
        :search="search"
        :loading="loading"
        :loading-text="$t('loading')"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }" v-if="isInRole('2')">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                          " " +
                          $t("branches.branch")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            hint="5 characters only!"
                            :rules="rules"
                            :counter="5"
                            v-model="editedItem.branchCode"
                            :label="$t('branches.branchCode')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.branchName"
                            :rules="[(value) => !!value || 'Required.']"
                            :label="$t('branches.branchName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.branchUrl"
                            :label="$t('branches.branchUrl')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      @click="save"
                      :min-width="100"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                      {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.branchCode != 'MAIN'" class="text-end">
            <v-tooltip top v-if="isInRole('3')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('4')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      valid: true,
      loading: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("branches.branchCode"), value: "branchCode" },
        { text: this.$t("branches.branchName"), value: "branchName" },
        { text: this.$t("branches.branchUrl"), value: "branchUrl" },
        { text: "", value: "actions" },
      ],
      branches: [],
      editedItem: {
        branchCode: "",
        branchName: "",
        branchUrl: "",
      },
      defaultItem: {
        branchCode: "",
        branchName: "",
        branchUrl: "",
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.loading = true;
    this.refreshTable();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Branch" : "Edit Branch";
    },
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.vouchers.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.branches.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.branches[this.editedIndex];

      axios
        .delete("Branch/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("Branch")
        .then((response) => {
          this.branches = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.branches[this.editedIndex];
          updatedItem.branchCode = this.editedItem.branchCode;
          updatedItem.branchName = this.editedItem.branchName;
          updatedItem.branchUrl = this.editedItem.branchUrl;

          axios
            .post("Branch/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.close();
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              axios.get("Account/GetGroups").then((response) => {
                this.groups = response.data.data;

                this.refreshTable();
              });
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.branches[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("Branch/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.close();
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              axios.get("Account/GetGroups").then((response) => {
                this.groups = response.data.data;
                this.refreshTable();
              });
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
